import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
function ElectricityBillEndOfMonth() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [meterreadingNo, setmeterreadingNo] = useState("");
  const userState = useSelector((state) => state.changeTheState.user);
  const [selprojectname, setselprojectname] = useState("");
  const [selectedpid, setselectedpid] = useState("");
  const [selecteduno, setselecteduno] = useState("");
  const [selunitid, setselunitid] = useState("");
  const [meterNo, setMeterNo] = useState("");
  const [readingValue, setreadingValue] = useState(0);
  const [dateValue, setDateValue] = useState("");
  const [timeValue, setTimeValue] = useState("");
  const [monthenddate, setmonthenddate] = useState("");
  const [monthendtime, setmonthendtime] = useState("");
  const [monthendreading, setmonthendreading] = useState(0);
  const [listElectricityBill, setListElectricityBill] = useState([]);
  const [search, setSearch] = useState("");
  const [tableflag, settableflag] = useState("");
  const [isloading, setisloading] = useState(true);
  const [startdatesort, setStartdatesort] = useState("");
  const [enddatesort, setEnddatesort] = useState("");

  const filteredData = listElectricityBill.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const Startmonthreadingdate = item.READINGDATE;
    const endmonthreadingdate = item.ENDMONTHDATE;

    if (searchData.length === 0 && !startdatesort && !enddatesort) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ENDMONTHREADING",
        "READINGVALUE",
        "METERNO",
        "UNITNO",
        "PROJECTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort ||
        (Startmonthreadingdate || endmonthreadingdate) >= startdatesort) &&
      (!enddatesort ||
        (Startmonthreadingdate || endmonthreadingdate) <= enddatesort);

    return textMatch && dateMatch;
  });

  function fetchElcticData() {
    axios
      .get(`${BASEURL}/getlistofelectricitybill`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setListElectricityBill(response.data);
        setisloading(false);
      })
      .catch((err) => {
        alert("Server error");
        setisloading(false);
      });
  }

  async function saveMeterReading() {
    try {
      const response = await axios.post(
        `${BASEURL}/savemeterreadings`,
        {
          TYPEOFREADING: "MONTHEND",
          READINGNO: meterreadingNo,
          PROJECTNO: selectedpid,
          UNITID: selunitid,
          METERNO: meterNo,
          READINGDATE: moment(dateValue).format("YYYY/MM/DD"),
          READINGTIME: timeValue,
          READINGVALUE: readingValue,
          CREATEDBY: userState.emailId.split("@")[0].toUpperCase(),
          ENDMONTHDATE: moment(monthenddate).format("YYYY/MM/DD"),
          ENDMONTHTIME: monthendtime,
          ENDMONTHREADING: monthendreading,
          ENDMONTHREADINGCREATEDBY: userState.emailId
            .split("@")[0]
            .toUpperCase(),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (err) {
      alert(err.response.data.message);
    }
  }
  useEffect(() => {
    scrollToSection();
    fetchElcticData();
  }, []);

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const FetchTableValuesInFields = async (item) => {
    console.log(item);
    settableflag(1);
    setreadingValue(item.READINGVALUE);
    setmonthendreading(item.ENDMONTHREADING);
    setMeterNo(item.METERNO);
    setDateValue(item.READINGDATE ? item.READINGDATE.slice(0, 10) : "");
    setTimeValue(item.READINGTIME ? item.READINGTIME.slice(11, 19) : "");
    setmonthenddate(item.ENDMONTHDATE ? item.ENDMONTHDATE.slice(0, 10) : "");
    setmonthendtime(item.ENDMONTHTIME ? item.ENDMONTHTIME.slice(11, 19) : "");
    setselunitid(item.UNITNO);

    setselectedpid(item.PROPERTYNO);
    setselprojectname(item.PROJECTNAME);
    setmeterreadingNo(item.RECORDNO);

    const response = await axios.post(
      `${BASEURL}/returnunitno`,
      {
        UNITID: item.UNITNO,
        PROPERTYID: item.PROPERTYNO,
      },
      {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      }
    );

    setselecteduno(response.data[0].RESULT);
  };

  const clearallinputs = () => {
    setreadingValue("");
    setMeterNo("");
    setDateValue("");
    setTimeValue("");
    setselecteduno("");
    setselectedpid("");
    setmeterreadingNo("");
    setmonthenddate("");
    setmonthendtime("");
    setmonthendreading("");
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="elctric__page">
      <MenuNavbar />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Electricity Bill End Of Month"}
              </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p style={{ width: "350px" }}>
                    <label className="garage_id_ ">
                      Meter Reading Number:{" "}
                    </label>
                  </p>
                  <div
                    className="ml-4 text-red-600 font-bold"
                    disabled={tableflag === 1}
                  >
                    {meterreadingNo}
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Project Name:</label>
                  </p>
                  <div>
                    <input
                      value={selprojectname}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Unit No:</label>
                  </p>
                  <div>
                    <input
                      value={selecteduno}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Meter Number</label>
                  </p>
                  <div>
                    <input
                      value={meterNo}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">
                      Month Start Reading value :
                    </label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={readingValue}
                      type="number"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Month Start Date</label>
                  </p>
                  <div>
                    <input
                      value={dateValue}
                      readOnly
                      type="date"
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Month Start Time :</label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={timeValue}
                      type="time"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Month End Date :</label>
                  </p>
                  <div>
                    <input
                      value={monthenddate}
                      onChange={(e) => {
                        setmonthenddate(e.target.value);
                      }}
                      type="date"
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Month End Time :</label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={monthendtime}
                      onChange={(e) => {
                        setmonthendtime(e.target.value);
                      }}
                      type="time"
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">
                      Month end Reading value :
                    </label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={monthendreading}
                      onChange={(e) => {
                        setmonthendreading(e.target.value);
                      }}
                      type="number"
                    />
                  </div>
                </div>
              </aside>
              <div className="savebutton">
                <button
                  className="buttonunit mr-4"
                  onClick={() => {
                    if (!selprojectname) {
                      alert("Kindly Supply Project Name");
                    } else if (!selunitid) {
                      alert("Kindly Supply Unit Number");
                    } else if (!meterNo) {
                      alert("Kindly Supply Meter Number");
                    } else if (!readingValue) {
                      alert("Kindly Supply Reading Value");
                    } else if (!dateValue) {
                      alert("Kindly Supply Date Value");
                    } else if (!timeValue) {
                      alert("Kindly Supply Time Value");
                    } else if (!monthenddate) {
                      alert("Kindly Supply month end date");
                    } else if (!monthendtime) {
                      alert("Kindly Supply month end time");
                    } else if (!monthendreading) {
                      alert("Kindly Supply month end reading");
                    } else {
                      clearallinputs();
                      settableflag(0);
                      saveMeterReading();
                      fetchElcticData();
                    }
                  }}
                >
                  Submit
                </button>
                <button
                  className="buttonunit"
                  onClick={() => {
                    clearallinputs();
                    settableflag(0);
                  }}
                >
                  New Record
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>{" "}
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="searchhhhhh filter__no__search">
        <p>
          <label className="garage_id_">Start date</label>
        </p>
        <div>
          <input
            required
            className="input_1"
            type="date"
            value={startdatesort.split("T")[0]}
            onChange={(e) => setStartdatesort(e.target.value)}
          />
        </div>
        <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>PROPERTY NAME</td>
              <td style={{ textAlign: "right", paddingRight: "50px" }}>
                UNIT NO
              </td>
              <td>METER NO</td>
              <td style={{ textAlign: "right" }}>READINGVALUE</td>
              <td>READINGDATE</td>
              <td>END MONTH DATE</td>
              <td>END MONTH READING</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.UNITNO ? item.UNITNO : "NA"}
                  </td>
                  <td className="w-[100px] text-right ">
                    {item.METERNO ? item.METERNO : ""}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.READINGVALUE ? item.READINGVALUE : "NA"}
                  </td>
                  <td>
                    {item.READINGDATE
                      ? moment(item.READINGDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>
                    {item.ENDMONTHDATE
                      ? moment(item.ENDMONTHDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>{item.ENDMONTHREADING ? item.ENDMONTHREADING : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={async () => {
                      clearallinputs();
                      await FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <></>
    </div>
  );
}

export default ElectricityBillEndOfMonth;
