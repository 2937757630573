import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
function KYCTYPES(props) {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [inspectiondata, setinspectiondata] = useState([]);
  const [selinspectiontype, setselinspectiontype] = useState("");
  const [inspectionitem, setinspectionitem] = useState("");
  const [Types, setTypes] = useState("new");
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const state = useSelector((state) => state.changeTheState);
  const UserEmail = state?.user ? state?.user?.emailId?.split("@")[0] : "N/A";
  console.log(UserEmail);
  console.log(menu);
  const currentDate = new Date();
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  console.log(toDateFilter);
  const kycDate =
    toDateFilter.slice(8, 10) +
    "/" +
    toDateFilter.slice(5, 7) +
    "/" +
    toDateFilter.slice(0, 4);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const filteredData = inspectiondata.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["KYC"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    getInspectionList();
  }, []);

  const FetchTableValuesInFields = (item) => {
    setselinspectiontype(item.KYC);
    // setinspectionitem(item.INSPECTIONITEM);
    setTypes("Update");
  };

  const getInspectionList = () => {
    axios
      .get(`${BASEURL}/getkyc`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setinspectiondata(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching Inspection List:", error);
        setisloading(false);
      });
  };

  const clearallinputs = () => {
    setselinspectiontype("");
    setinspectionitem("");
  };

  async function Savedetails() {
    const type = Types;
    console.log(type);
    console.log(kycDate);
    console.log(UserEmail);
    console.log(selinspectiontype?.toUpperCase());
    try {
      const response = await axios.post(
        `${BASEURL}/savekyc/${type}`,
        {
          KYCTYPE: selinspectiontype?.toUpperCase(),
          KYCCREATEDBY: UserEmail,
          KYCCREATEDDATE: moment(kycDate).format("YYYY/MM/DD"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
      console.log(response.data.message);
      setTypes("new");
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "Inspection Master"}
                </h1>
              </header>
              <section className="section__newgarage">
                <aside className="aside__new__garage  inpection__master">
                  <div>
                    <p>
                      <label className="garage_id_">KYC Type</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        type="text"
                        value={selinspectiontype}
                        onChange={(e) => {
                          setselinspectiontype(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  {/* <div>
                    <p>
                      <label className="garage_id_">Inspection Item </label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        type="text"
                        value={inspectionitem}
                        onChange={(e) => {
                          setinspectionitem(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div> */}
                </aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!selinspectiontype) {
                        alert("Kindly supply Inspection Type");
                      } else {
                        await Savedetails();
                        clearallinputs();
                        getInspectionList();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
        </div>
        <div className="service_details">
          <table className="table_1 ">
            <thead>
              <tr>
                <td>KYC TYPE</td>

                <td>EDIT</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td style={{ width: "240px", paddingLeft: "10px" }}>
                    {item.KYC ? item.KYC : "NA"}
                  </td>

                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      width: "80px",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

// InspectionMaster.propTypes = {};

export default KYCTYPES;
