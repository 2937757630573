import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "../../PropertyManagement/PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select";
import { useLocation } from "react-router-dom";
const getLastDayOfMonth = (year, month) => {
  const lastDay = new Date(year, month + 1, 0).getDate();
  return lastDay;
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const lastDayOfMonth = getLastDayOfMonth(currentYear, currentMonth);
  return `${currentYear}-${(currentMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;
};

function ElectricityBillClient() {
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [search, setSearch] = useState("");
  const sessiontoken = window.sessionStorage.getItem("session-token"); // State for search query
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  // const [startdatesort, setStartdatesort] = useState('')
  //   const firstDayOfMonth = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth() + 0,
  //     2
  //   );
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [Startdatesort, setStartdatesort] = useState("");
  function getLastDayOfPreviousMonth() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    // Calculate the previous month
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousYear = month === 0 ? year - 1 : year;

    // Get the last day of the previous month
    const lastDayOfPreviousMonth = new Date(previousYear, previousMonth + 1, 0);

    // Format the date as "YYYY-MM-DD"
    const formattedYear = lastDayOfPreviousMonth.getFullYear();
    const formattedMonth = (lastDayOfPreviousMonth.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const formattedDay = lastDayOfPreviousMonth
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  }

  const lastDayOfPreviousMonth = getLastDayOfPreviousMonth();
  console.log(lastDayOfPreviousMonth); // Output: "YYYY-MM-DD" for the last day of the previous month

  const endOfPreviousMonth = getLastDayOfPreviousMonth();
  console.log(endOfPreviousMonth);

  const getListOfProjectIds = () => {
    console.log(Startdatesort);
    const monthenddate =
      Startdatesort.slice(0, 4) +
      "/" +
      Startdatesort.slice(5, 7) +
      "/" +
      Startdatesort.slice(8, 10);
    console.log(monthenddate);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/gethistoricbilllist`,
        {
          CURRENTMONTHENDDATE: monthenddate,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofprojectid(response.data ? response.data : "");
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Project Idss:", error);
      });
  };
  useEffect(() => {
    if (Startdatesort) {
      getListOfProjectIds();
    }
  }, [Startdatesort]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const uniqueIdentifier = `${item.CLIENTCODE}-${item.UNITNUMBER}-${item.SUBUNITNO}-${item.LEASESTARTDATE}`;
      const isAlreadySelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.uniqueIdentifier === uniqueIdentifier
      );

      if (isAlreadySelected) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.uniqueIdentifier !== uniqueIdentifier
        );
      } else {
        return [...prevSelectedItems, { ...item, uniqueIdentifier }];
      }
    });
  };

  async function getnewElectricitybillNumber() {
    try {
      const response = await axios.get(
        `${BASEURL}/getnewElectricitybillNumber`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      if (response.status === 200) {
        return response?.data[0]?.NEXBILLTNO;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while generating Electricity bill Number`, error);
      alert("Failed to generate Electricity bill Number");
    }
  }

  console.log(selectedItems);
  async function HandleSave() {
    const promises = selectedItems.map(async (item) => {
      const totalAmountCharged = item.DAYSCONSUMED * item.RATEPERUNIT;

      console.log({
        PROPERTYID: item.PROJECTID,
        CLIENTCODE: item.CLIENTCODE,
        CLIENTUNITID: item.UNITID,
        CLIENTSUBUNITID: item.SUBUNITID,
        FORPERIOD: endOfPreviousMonth,
        TOTALDAYSCOUNTED: item.TOTALDAYS,
        TOTALUNITSCONSUMED: item.DAYSCONSUMED,
        TOTALRATEPERUNITCHARGED: item.RATEPERUNIT,
        TOTALAMOUNTCHARGED: totalAmountCharged,
      });

      const billNo = await getnewElectricitybillNumber();

      return axios.post(
        `${BASEURL}/savebulkelectrictybill`,
        {
          BILLNO: Number(billNo),
          PROPERTYID: item.PROJECTID,
          CLIENTCODE: item.CLIENTCODE,
          CLIENTUNITID: item.UNITID,
          CLIENTSUBUNITID: item.SUBUNITID,
          FORPERIOD: endOfPreviousMonth,
          TOTALDAYSCOUNTED: item.TOTALDAYS,
          TOTALUNITSCONSUMED: item.DAYSCONSUMED,
          TOTALRATEPERUNITCHARGED: item.RATEPERUNIT,
          TOTALAMOUNTCHARGED: totalAmountCharged,
          TOTALNUMBEROFSTUDENTS: 0,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
    });

    Promise.all(promises)
      .then((responses) => {
        alert("All items saved successfully!");
      })
      .catch((error) => {
        console.error("Error posting selected item details:", error);
      });
  }

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const filteredData = listofprojectid.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "CLIENTCODE",
        "UNITNUMBER",
        "LEASESTARTDATE",
        "SUBUNITNO",
        "LEASEENDDATE",
        "DAYSCONSUMED",
        "METERREADING",
        "RATEPERUNIT",
        "TOTALDAYS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    setisloading(false);
    // Calculate the last day of the previous month
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    // Calculate the previous month
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousYear = month === 0 ? year - 1 : year;

    // Get the last day of the previous month
    const lastDayOfPreviousMonth = new Date(previousYear, previousMonth + 1, 0);

    // Format the date as "YYYY-MM-DD"
    const formattedYear = lastDayOfPreviousMonth.getFullYear();
    const formattedMonth = (lastDayOfPreviousMonth.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const formattedDay = lastDayOfPreviousMonth
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    // Set the value of the input field to the last day of the previous month
    setStartdatesort(formattedDate);
  }, []);

  // if (isloading) {
  //   return <Loading />;
  // }
  return (
    <div className="elctric__page">
      <MenuNavbar />

      <header className="header__of__main">
        <h1 className="header__of__page">
          {menu ? menu : "Electricity Historical Details"}
        </h1>
      </header>
      <div className="lg:flex gap-2 items-center filter__no__search mx-2 lg:mx-10">
        <p>
          <label>Select the Date</label>
        </p>
        <div>
          <input
            required
            className=""
            type="date"
            value={Startdatesort}
            onChange={(e) => setStartdatesort(e.target.value)}
          />
        </div>

        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="search__icon">
            <FaSearch />
          </div>
        </div>
        {/* <button
          style={{ backgroundColor: "#2f2f5a", color: "white" }}
          className="button__logout__navbar  text-white p-1 rounded "
          onClick={HandleSave}
        >
          SAVE
        </button> */}
        {/* <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div> */}
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <div className="service_detials">
          <table className="table_1 " style={{ fontSize: "12px" }}>
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <td>PROJECT NAME</td>
                <td>CLIENT CODE</td>
                <td className="text-right">UNIT NUMBER</td>
                <td>LEASE START DATE</td>
                <td>SUB UNIT NUMBER</td>
                <td>LEASE END DATE</td>
                <td className="text-right">DAYS CONSUMED</td>
                <td className="text-right">METER READINGS</td>
                <td className="text-right">TOTAL AMOUNT CHARGED</td>
                <td className="text-right">RATE PER UNIT</td>

                <td className="text-right">TOTAL DAYS</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index} style={{ textTransform: "uppercase" }}>
                    <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                    <td style={{ textAlign: "right", paddingRight: "50px" }}>
                      {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                    </td>
                    <td className="text-right">
                      {item.UNITNUMBER ? item.UNITNUMBER : "NA"}
                    </td>
                    <td>
                      {item.LEASESTARTDATE
                        ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                    <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                    <td>
                      {item.LEASEENDDATE
                        ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                        : "N/A"}
                    </td>
                    <td className="text-right">
                      {item.DAYSCONSUMED ? item.DAYSCONSUMED : "NA"}
                    </td>
                    <td className="text-right">
                      {item.METERREADING ? item.METERREADING : "NA"}
                    </td>
                    <td
                      style={{
                        cursor: "pointer",

                        textAlign: "right",
                      }}
                    >
                      {(item?.DAYSCONSUMED * item?.RATEPERUNIT).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </td>
                    <td className="text-right">
                      {item.RATEPERUNIT ? item.RATEPERUNIT : "NA"}
                    </td>
                    <td className="text-right">
                      {item.TOTALDAYS ? item.TOTALDAYS : "NA"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    No data for selected date
                  </td>
                </tr>
              )}
            </tbody>
            {/* <tbody>
            {listofprojectid.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.CLIENTCODE === item.CLIENTCODE
                        )}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </label>
                  </td>
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                  </td>
                  <td>{item.UNITNUMBER ? item.UNITNUMBER : "NA"}</td>
                  <td>
                    {item.LEASESTARTDATE
                      ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                  <td>
                    {item.LEASEENDDATE
                      ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td>{item.DAYSCONSUMED ? item.DAYSCONSUMED : "NA"}</td>
                  <td>{item.METERREADING ? item.METERREADING : "NA"}</td>
                  <td>{item.RATEPERUNIT ? item.RATEPERUNIT : "NA"}</td>
                  <td>{item.TOTALDAYS ? item.TOTALDAYS : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {item.DAYSCONSUMED * item.RATEPERUNIT}
                  </td>
                </tr>
              );
            })}
          </tbody> */}
          </table>
        </div>
      )}
    </div>
  );
}

export default ElectricityBillClient;
