import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import "../PropertyManagementCss/UnitMaster.css";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";

function UnitMaster() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [unitmastertable, setunitmastertable] = useState([]);
  const [listofprojectnames, setlistofprojectnames] = useState([]);
  const [unitid, setunitid] = useState("");
  const [unitnumber, setunitnumber] = useState(null);
  const [unittype, setunittype] = useState([]);
  const [selunittype, setselunittype] = useState("");
  const [unitrooms, setunitrooms] = useState(null);
  const [unitlocation, setunitlocation] = useState("");
  const [unitrate, setunitrate] = useState(null);
  const [date, setDate] = useState(new Date().toISOString());
  const [createdby, setcreatedby] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [projectid, setprojectid] = useState("");
  const [search, setSearch] = useState("");
  const [subunits, setsubunits] = useState("");
  const [meternumber, setmeternumber] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [tableflag, settableflag] = useState(0);
  const [isloading, setisloading] = useState(true);
  const filteredData = unitmastertable.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "PROJECTUNIT",
        "UNITNO",
        "UNITTYPE",
        "ROOMS",
        "RATE",
        "LOCATION",
        "ELECTRICITYMETER",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    setcreatedby(username?.split("@")[0].toUpperCase());
    getListOfProjectIds();
    getListOfUnitType();
    getListOfUnitsUnderProperties();
  }, []);

  const FetchTableValuesInFields = (item) => {
    settableflag(1);
    setselprojectname(item.PROJECTNAME);
    setprojectid(item.PROJECTID);
    setunitid(item.PROJECTUNIT);
    setunitnumber(item.UNITNO);
    setselunittype(item.UNITTYPE);
    setsubunits(item.SUBUNITS);
    setunitrooms(item.ROOMS);
    setunitlocation(item.LOCATION);
    setunitrate(item.RATE);
    setmeternumber(item.ELECTRICITYMETER);
  };

  const clearallinputs = () => {
    setselprojectname("");
    setunitid("");
    setunitnumber("");
    setselunittype("");
    setunitrooms("");
    setunitlocation("");
    setunitrate("");
    setsubunits("");
    setmeternumber("");
  };

  const getNewUnitId = (value) => {
    axios
      .post(
        `${BASEURL}/NewUnitId`,
        {
          projectid: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setunitid(response.data[0].UNITID);
      })
      .catch((error) => {
        alert(error.response.data.message);
        setselprojectname("");
        setprojectid("");
        console.log("Error While fetching New Unit Id", error);
      });
  };

  const getListOfProjectIds = () => {
    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectnames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Ids:", error);
      });
  };

  const getListOfUnitType = () => {
    axios
      .get(`${BASEURL}/UnitTypeMasterDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setunittype(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Ids:", error);
      });
  };

  const getListOfUnitsUnderProperties = () => {
    axios
      .get(`${BASEURL}/ListOfUnitsUnderProperties`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setunitmastertable(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Units Under Properties:", error);
        setisloading(false);
      });
  };

  async function SaveUnits() {
    try {
      const response = await axios.post(
        `${BASEURL}/SaveUnit`,
        {
          PROJECTID: projectid,
          PROJECTUNITID: unitid,
          PROJECTUNITNO: unitnumber,
          PROJECTUNITTYPE: selunittype,
          PROJECTUNITROOMS: unitrooms,
          PROJECTUNITLOCATION: unitlocation,
          PROJECTUNITRATE: unitrate,
          DOCUMENTCREATEDBY: createdby,
          DOCUMENTCREATEDDATE: moment(date).format("YYYY/MM/DD"),
          PROJECTSUBUNITS: subunits,
          UNITMETERNUMBER: meternumber,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  function formatDate(inputDate) {
    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const parts = inputDate?.split(" ");
    const month = months[parts[0]];
    const day = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    const time = parts[3].slice(0, -2);
    const ampm = parts[3].slice(-2);

    const formattedDate = new Date(year, month, day);
    const dd = String(formattedDate.getDate()).padStart(2, "0");
    const mm = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const yyyy = formattedDate.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  }

  const handleOptionChange = (selectedOption) => {
    const selected = listofprojectnames.find(
      (item) => item.PROJECTNAME === selectedOption.value
    );
    getNewUnitId(selected.PROJECTID);
    setprojectid(selected.PROJECTID);
  };

  const uniqueProjectNames = [
    ...new Set(listofprojectnames.map((item) => item.PROJECTNAME)),
  ];

  const options = [
    { value: "", label: "" },
    ...uniqueProjectNames.map((item) => ({ value: item, label: item })),
  ];

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Unit Master"}
              </h1>
            </header>

            <section className="section__newgarage">
              <aside className="aside__new__garage UnitMaster__width">
                <div>
                  <p>
                    {" "}
                    <label className="garage_id_">Project Name</label>
                  </p>
                  <div>
                    {" "}
                    <Select
                      styles={customStyless}
                      onChange={(e) => {
                        if (e.value === "") {
                          setunitid("");
                          setselprojectname("");
                          alert("Kindly supply proper Project Name");
                        } else {
                          setselprojectname(e.value);
                          handleOptionChange(e);
                        }
                      }}
                      value={
                        selprojectname
                          ? { label: selprojectname, value: selprojectname }
                          : { label: "", value: "" }
                      }
                      options={options}
                      isDisabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Unit ID </label>
                  </p>
                  <div>
                    <input
                      className="inputttt"
                      required
                      value={unitid}
                      readOnly
                      disabled={tableflag === 1}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Unit Number </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      required
                      value={unitnumber}
                      onChange={(e) => {
                        setunitnumber(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Unit Type</label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(e) => {
                        if (e.value === "") {
                          alert("Kindly Supply Unit Type");
                          setselunittype("");
                        } else {
                          setselunittype(e.value);
                        }
                      }}
                      value={
                        selunittype
                          ? { label: selunittype, value: selunittype }
                          : { label: "", value: "" }
                      }
                      options={[
                        { value: "", label: "" },
                        ...unittype.map((item) => ({
                          value: item.UNITTYPE,
                          label: item.UNITTYPE,
                        })),
                      ]}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Meter Number </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      value={meternumber}
                      onChange={(e) => {
                        setmeternumber(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>

                <div>
                  <p>
                    <label className="garage_id_">Unit Rooms </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      value={unitrooms}
                      onChange={(e) => {
                        setunitrooms(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Sub Units </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      value={subunits}
                      onChange={(e) => {
                        setsubunits(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Unit Location </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      value={unitlocation}
                      onChange={(e) => {
                        setunitlocation(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Unit Cost </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      value={unitrate}
                      onChange={(e) => {
                        setunitrate(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>

              <div className="savebutton ">
                <button
                  className="buttonunit"
                  onClick={() => {
                    clearallinputs();
                    settableflag(0);
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit ml-5"
                  onClick={async () => {
                    if (!selprojectname) {
                      alert("Kindly Supply Project Name");
                    } else if (!unitid) {
                      alert("Kindly Supply Unit Id");
                    } else if (!unitnumber) {
                      alert("Kindly Supply Unit Number");
                    } else if (!selunittype) {
                      alert("Kindly Supply Unit Type");
                    } else if (!meternumber) {
                      alert("Kindly Supply Meter Number");
                    } else if (!subunits) {
                      alert("Kindly Supply Sub Units");
                    } else if (!unitrooms) {
                      alert("Kindly Supply Unit Rooms");
                    } else if (!unitlocation) {
                      alert("Kindly Supply Unit Location");
                    } else if (!unitrate) {
                      alert("Kindly Supply Unit Cost");
                    } else {
                      await SaveUnits();
                      clearallinputs();
                      getListOfProjectIds();
                      getListOfUnitsUnderProperties();
                      settableflag(0);
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>{" "}
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1">
          <thead>
            <tr>
              <td>PROJECT NAME</td>

              <td style={{ textAlign: "right", width: "70px" }}>UNIT ID</td>
              <td style={{ textAlign: "right" }}>UNIT NUMBER</td>
              <td>UNIT TYPE</td>
              <td className="text-right">METER NO</td>
              <td style={{ textAlign: "right" }}>SUB UNITS</td>
              <td style={{ textAlign: "right" }}>UNIT ROOMS</td>
              <td style={{ textAlign: "right" }}>UNIT COST</td>
              <td>UNIT LOCATION</td>
              <td>EDIT</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right" }}>
                    {item.PROJECTUNIT ? item.PROJECTUNIT : ""}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.UNITNO
                      ? item.UNITNO.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {item.UNITTYPE ? item.UNITTYPE : "NA"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.ELECTRICITYMETER ? item.ELECTRICITYMETER : "NA"}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {item.SUBUNITS ? item.SUBUNITS : "NA"}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {item.ROOMS
                      ? item.ROOMS.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })
                      : ""}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {item.RATE
                      ? item.RATE.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {item.LOCATION ? item.LOCATION : "NA"}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      clearallinputs();
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UnitMaster;
