import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import MenuNavbar from "./MenuNavbar";
import Loading from "../../Components/Loading/Loading";
import NavbarDashboard from "../../Pages/NavbarClient/ClientMenuNavbar";
import { statementPass } from "../../Redux/Action/selectStatements";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
// import NavbarDashboard from "../../Pages/NavbarClient/ClientMenuNavbar";
function TenantUnitTransfer() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getTenenatId, setgetTenenatId] = useState("");
  const [ProjectId, setProjectId] = useState("");
  const [ProjectAndUnitNo, setProjectAndUnitNo] = useState({
    PROJECT: "",
    UNIT: "",
  });
  const [clientName, setClientName] = useState([]);
  const [toPropertylist, setToPropertylist] = useState([]);
  const [UnitNo, setUnitNo] = useState([]);
  const [SubUnitNo, setSubUnitNo] = useState([]);
  const [SubUnitValue, setSubUnitValue] = useState("");
  const [ListOfTransfers, setListOfTransfers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [DateValue, setDateValue] = useState("");
  const [ReasonTransfer, setReasonTransfer] = useState("");
  const getnewTenantId = () => {
    axios
      .get(`${BASEURL}/getnewtransferid`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setgetTenenatId(response.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching fuel station data:", error);
      });

    axios
      .get(`${BASEURL}/clientlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setClientName(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });

    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setToPropertylist(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
    axios
      .get(`${BASEURL}/getlistoftransfers`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setListOfTransfers(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
  };
  const clearAllInputs = () => {
    // setgetTenenatId("");
    // // setClientName("");
    // setProjectAndUnitNo({
    //   PROJECT: "",
    //   UNIT: "",
    // });
    // setSelectedClientName("");
    // setClientCode("");
    // // setToPropertylist("");
    // setUnitNo([]);
    // setSubUnitNo([]);
    // setSubUnitValue("");
    // setListOfTransfers([]);
    // setSearch("");
    // setSelectedItems([]);
    window.location.reload();
  };

  const [selectedClientName, setSelectedClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const handleClientNameChange = (selectedOption) => {
    setSelectedClientName(selectedOption.value);

    const selectedClient = clientName.find(
      (item) => item.CLIENTNAME === selectedOption.value
    );

    setClientCode(selectedClient ? selectedClient.CLIENTCODE : "");
    console.log(selectedClient ? selectedClient.CLIENTCODE : "");
    if (selectedClient.CLIENTCODE) {
      axios
        .post(
          `${BASEURL}/getClientDetails`,
          { CLIENTCODE: selectedClient.CLIENTCODE },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response.data[0], "setProjectAndUnitNo");
          console.log(clientCode, "clientCode");
          setProjectAndUnitNo(response.data[0]);
          // console.log(response.data[0]?.NEXTNO);
        })
        .catch((err) => {
          alert("Server error");
        });
    }
  };

  useEffect(() => {
    scrollToSection();
    getnewTenantId();
  }, []);

  const [propertyname, setpropertyname] = useState("");
  const HandlePropertyName = (value) => {
    setProjectId(value.value);
    setpropertyname(value.label);
    console.log(value.value);
    axios
      .post(
        `${BASEURL}/ListOfUnitIds`,
        { PROJECTID: value.value },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setUnitNo(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
  };

  const HandleTheUnitNumber = (selected) => {
    const selectedUnitnumber = selected.value;
    console.log(ProjectId);
    console.log(selected.value);
    axios
      .post(
        `${BASEURL}/toFetchSubunit`,
        { PROJECTID: ProjectId, UNITNO: selected.value },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setSubUnitNo(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
  };

  const HoldSubUnitNo = (selected) => {
    setSubUnitValue(selected.value);
  };

  const currentDate = new Date();
  const validateFields = () => {
    if (!selectedClientName) {
      alert("Please select a customer name.");
      return false;
    }

    if (!propertyname) {
      alert("Please select a TO PROPERTY.");
      return false;
    }

    if (!SubUnitValue) {
      alert("Please select a SUB UNIT NUMBER.");
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    const selectedDate = new Date(inputValue);

    const formattedDate = `${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(selectedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${selectedDate.getFullYear()}`;

    setDateValue(formattedDate);
  };
  console.log(DateValue);
  // console.log(ReasonTransfer);
  function HandleSavedDetails() {
    const confirmed = window.confirm("Are you sure you want to confirm?");

    if (confirmed && validateFields()) {
      axios
        .post(
          `${BASEURL}/changeofsubunit`,
          {
            TRANSFERID: getTenenatId,
            CUSTOMERCODE: clientCode,
            FROMPROPERTY: ProjectAndUnitNo.PROJECT,
            FROMUNIT: ProjectAndUnitNo.UNIT,
            TOPROPERTY: propertyname,
            TOUNIT: SubUnitValue,
            CREATEDBY: userState.emailId.split("@")[0].toUpperCase(),
            CREATEDDATE: moment(DateValue).format("YYYY/MM/DD"),
            REASONTRANSFER: ReasonTransfer,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response) {
            alert("SubUnit Transfer Saved Successfully");
            window.location.reload();
          }
          // setSubUnitNo(response.data);
          // console.log(response.data[0]?.NEXTNO);
        })
        .catch((err) => {
          alert("Server error");
        });
    }
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const FetchTableValuesInFields = (item) => {
    setgetTenenatId(item.TRANSFERNO);
    setSelectedClientName(item.CUSTOMERNAME);
    // setpropertyname(item.TOPROPERTY);
    setSubUnitValue(item.TOUNIT);
    // setProjectAndUnitNo(item);
    setProjectAndUnitNo({
      PROJECT: item.FROMPROPERTY,
      UNIT: item.FROMUNIT,
    });

    console.log(item.FROMPROPERTY);
  };
  const handleStatusFilterChange = (selectedOption) => {
    setStatusFilter(selectedOption.value);
  };

  // ... (existing code)

  const filteredData = ListOfTransfers.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && (statusFilter === "All" || !statusFilter)) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "TRANSFERID",
        "CUSTOMERNAME",
        "FROMPROPERTY",
        "FROMUNIT",
        "TOPROPERTY",
        "TOUNIT",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const statusMatch =
      statusFilter == "All" ||
      (statusFilter == "APPROVED" && item.APPROVED == "1") ||
      (statusFilter == "PENDING" &&
        item.APPROVED != "1" &&
        item.CANCELLED != "1") ||
      (statusFilter == "CANCELLED" && item.CANCELLED == "1");

    return textMatch && statusMatch;
  });

  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.TRANSFERNO === item.TRANSFERNO
    );

    setSelectedItems((prevSelected) =>
      isSelected
        ? prevSelected.filter(
            (selectedItem) => selectedItem.TRANSFERNO !== item.TRANSFERNO
          )
        : [...prevSelected, item]
    );
    console.log(selectedItems);
  };

  const handleSaveSelectedItems = async (value) => {
    console.log(value);

    console.table(selectedItems);
    console.log(selectedItems);

    try {
      await Promise.all(
        selectedItems.map(async (selectedItem) => {
          const response = await axios.post(
            `${BASEURL}/transferapproval`,
            {
              TRANSFERID: selectedItem.TRANSFERNO,
              APPROVEORCANCEL: value,
              APPROVEBY: userState.emailId.split("@")[0].toUpperCase(),
              APPROVEDDATE: moment(currentDate).format("YYYY/MM/DD"),
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          console.log(response.data);
        })
      );

      alert(`Sub Unit Transfer ${value}`);
      window.location.reload();
    } catch (err) {
      console.error("Server error", err.response.data.message);
    }
  };

  return (
    <div>
      <MenuNavbar />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between mr-5">
                <h1 className="header__of__page">
                  {menu ? menu : "TENANT SUB UNIT TRANSFER"}
                </h1>

                <h1>
                  <button
                    className="buttonunit ml-5"
                    type="submit"
                    onClick={HandleSavedDetails}
                  >
                    SAVE{" "}
                  </button>

                  <button
                    className="buttonunit ml-5"
                    type="submit"
                    onClick={clearAllInputs}
                  >
                    NEW
                  </button>
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">TRANSFER ID </label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {getTenenatId}
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> CUSTOMER NAME </label>
                    </p>
                    <div className="  w-[100%]" style={{ zIndex: 500 }}>
                      <Select
                        value={{
                          value: selectedClientName
                            ? selectedClientName
                            : clientCode,
                          label: selectedClientName
                            ? selectedClientName
                            : clientCode,
                        }}
                        className=" "
                        options={clientName.map((item) => ({
                          value: item["CLIENTNAME"],
                          label: item["CLIENTNAME"],
                        }))}
                        onChange={handleClientNameChange}
                      />
                    </div>
                    {/* <div>
                  <Select styles={customStyless} />
                </div> */}
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">FROM PROPERTY </label>
                    </p>
                    <div>
                      <input
                        value={ProjectAndUnitNo?.PROJECT}
                        // disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> FROM SUB UNIT </label>
                    </p>
                    <div>
                      <input
                        value={ProjectAndUnitNo?.UNIT}
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">TO PROPERTY</label>
                    </p>
                    <div>
                      <Select
                        value={{ value: propertyname, label: propertyname }}
                        className=" "
                        options={toPropertylist.map((item) => ({
                          value: item.PROJECTID,
                          label: item.PROJECTNAME,
                        }))}
                        onChange={HandlePropertyName}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> TO UNIT </label>
                    </p>
                    <div>
                      <Select
                        // value={clientName}

                        options={UnitNo.map((item) => ({
                          value: item.UNITNO,
                          label: item.UNITNO,
                        }))}
                        required
                        className="input_1"
                        onChange={HandleTheUnitNumber}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">SUB UNIT NUMBER</label>
                    </p>
                    <div>
                      <Select
                        value={{ value: SubUnitValue, label: SubUnitValue }}
                        className=" "
                        options={SubUnitNo.map((item) => ({
                          value: item.SUBUNITNO,
                          label: item.SUBUNITNO,
                        }))}
                        onChange={HoldSubUnitNo}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> DATE OF TRANSFER</label>
                    </p>
                    <div>
                      <input type="date" onChange={handleChange}></input>
                    </div>
                  </div>
                  {/* <div>
                    <p> */}
                  {/* <button  className="buttonunit ml-5" type = "submit" onClick={HandleSavedDetails}>SAVE </button> */}
                  {/* </p>
                    </div> */}
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_"> REASON TRANSFER </label>
                    </p>
                    <div>
                      <textarea
                        type="text"
                        class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[80px] max-h-[80px]"
                        onChange={(e) => setReasonTransfer(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  {/* <div>
                    <p> */}
                  {/* <button  className="buttonunit ml-5" type = "submit" onClick={HandleSavedDetails}>SAVE </button> */}
                  {/* </p>
                    </div> */}
                </aside>
              </section>
            </main>
          </div>
        </div>
        <div className="searchhhhhh ">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
          <div className=" flex items-center">
            <p>Filter By Status : </p>
            <Select
              value={{ value: statusFilter, label: statusFilter }}
              options={[
                { value: "All", label: "All" },
                { value: "APPROVED", label: "APPROVED" },
                { value: "PENDING", label: "PENDING" },
                { value: "CANCELLED", label: "CANCELLED" },
              ]}
              onChange={handleStatusFilterChange}
              styles={{
                container: (provided) => ({ ...provided, width: 170 }),
              }}
            />
          </div>
          <div className="flex text-end ">
            <div>
              <button
                className="buttonunit ml-5"
                type="submit"
                onClick={() => handleSaveSelectedItems("APPROVE")}
              >
                APPROVE
              </button>
            </div>
            <div>
              <button
                className="buttonunit ml-5"
                type="submit"
                onClick={() => handleSaveSelectedItems("CANCEL")}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
        <div className="service_detials">
          <table className="table_1">
            <thead>
              <tr>
                <td></td>
                <td>TRANSFER ID</td>
                <td style={{ textAlign: "right", width: "70px" }}>
                  CUSTOMER NAME
                </td>
                <td style={{ textAlign: "right", paddingRight: "80px" }}>
                  FROM PROPERTY
                </td>
                <td>FROM SUB UNIT</td>
                <td>TO PROPERTY</td>
                <td>TO SUB UNIT</td>
                <td>STATUS</td>
                <td>EDIT</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => {
                return (
                  <tr
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.TRANSFERNO === item.TRANSFERNO
                        )}
                        onChange={() => handleCheckboxChange(item)}
                        disabled={item.APPROVED == "1" || item.CANCELLED == "1"}
                      />
                    </td>
                    <td>{item.TRANSFERNO ? item.TRANSFERNO : "NA"}</td>
                    <td style={{ textAlign: "right" }}>
                      {item.CUSTOMERNAME ? item.CUSTOMERNAME : ""}
                    </td>

                    <td style={{ textAlign: "right", paddingRight: "80px" }}>
                      {item.FROMPROPERTY ? item.FROMPROPERTY : "NA"}
                    </td>

                    <td style={{ textAlign: "left" }}>
                      {item.FROMUNIT ? item.FROMUNIT : "NA"}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "80px" }}>
                      {item.TOPROPERTY ? item.TOPROPERTY : "NA"}
                    </td>

                    <td style={{ textAlign: "left" }}>
                      {item.TOUNIT ? item.TOUNIT : "NA"}
                    </td>
                    <td>
                      {item.APPROVED == "1"
                        ? "Approved"
                        : item.CANCELLED == "1"
                        ? "Cancelled"
                        : "Pending"}
                    </td>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "22px",
                        textAlign: "right",
                      }}
                      onClick={() => {
                        if (item.APPROVED != 1 && item.CANCELLED != 1) {
                          FetchTableValuesInFields(item);
                          scrollToSection();
                        }
                      }}

                      // disabled={item.APPROVED == 1 || item.CANCELLED == 1}
                    >
                      <BiSolidEditAlt />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TenantUnitTransfer;
