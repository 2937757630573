import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuNavbar from "./MenuNavbar";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import "../PropertyManagementCss/elctricpage.css";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";

function EditInspectionDetails() {
  const [InspectionType, setInspectionType] = useState("EXIT");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [isLoading, setIsloading] = useState(false);
  const [InspectionHeaderItem, setInspectionHeaderItem] = useState([]);
  const [InspectionDetails, setInspectionDetails] = useState([]);
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState?.userCode);
  const [exitDate, setexitDate] = useState("");
  const [exitcarriedBy, setexitcarriedBy] = useState("");
  const [inspectionexitnarration, setinspectionexitnarration] = useState("");
  const [inspectionexitcarrieddate, setinspectionexitcarrieddate] =
    useState("");
  const [Carriedoutby, setCarriedBy] = useState([]);
  const [exitStatusValues, setExitStatusValues] = useState([]);
  const [exitStatusNarrationValues, setExitStatusNarrationValues] = useState(
    []
  );

  const [DamageNarration, setDamageNarration] = useState("");
  const [DamageAmount, setDamageAmount] = useState("");
  const [DamagePaidBy, setDamagePaidBy] = useState("");
  const [Outstandingamt, setOutstandingamt] = useState("");

  const { inspectionData } = location.state;
  console.log(inspectionData);
  const { clientCode } = location.state;
  console.log(clientCode);
  const authToken = window.sessionStorage.getItem("auth-token");
  const BASEURL = process.env.REACT_APP_BASEURL;

  const [inspectionDate, setInspectionDate] = useState("");
  const [inspectionDocument, setInspectionDocument] = useState(null);
  const [inspectionDocumentNumber, setInspectionDocumentNumber] =
    useState(null);
  const [inspectionDocumentNarration, setInspectionDocumentNarration] =
    useState(null);
  const [preview, setPreview] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setInspectionDocument(file);
    if (file && file.type.startsWith("image/")) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    } else {
      setPreview(null); // Reset preview if it's not an image
    } // Store the file in the state variable
  };
  const handleExitStatusChange = (inspectionItem, value) => {
    setExitStatusValues((prevValues) => ({
      ...prevValues,
      [inspectionItem]: value,
    }));
  };

  const handleExitStatusNarrationChange = (inspectionItem, value) => {
    setExitStatusNarrationValues((prevValues) => ({
      ...prevValues,
      [inspectionItem]: value,
    }));
  };

  useEffect(() => {
    if (inspectionData) {
      axios
        .post(
          `${BASEURL}/getpropertyinspectionheader`,
          {
            INSPECTIONNO: inspectionData,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setInspectionHeaderItem(response.data[0]);
        });

      axios
        .post(
          `${BASEURL}/getpropertyinspectiondetails`,
          {
            INSPECTIONNO: inspectionData,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setInspectionDetails(response.data);
        });
      console.log(clientCode);
      axios
        .post(
          `${BASEURL}/gettenantoutstandingamount`,
          {
            CLIENTCODE: clientCode,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setOutstandingamt(response.data[0]);
        });
    }

    axios
      .get(`${BASEURL}/employeelist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCarriedBy(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
    axios
      .get(`${BASEURL}/getnewinspectionDocumentno`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data[0]?.DOCUMENTNO);
        setInspectionDocumentNumber(response.data[0]?.DOCUMENTNO);
      })
      .catch((err) => {
        // alert("Server error");
      });
    console.log(inspectionData);
    axios
      .get(
        `${BASEURL}/getSavedInspectionDocuments?INSPECTIONNO=${inspectionData}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setInspectionDocument(response.data[0].DOCUMENTATTACHED);
        setInspectionDocumentNumber(response.data[0]?.DOCUMENTNO);
        setInspectionDocumentNarration(response.data[0]?.NARRATION);
        setPreview(response.data[0].DOCUMENTATTACHED);
      })
      .catch((err) => {
        // alert("Server error");
      });

    axios
      .get(
        `${BASEURL}/getmeterreadings`,

        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setInspectionDetails(response.data);
      });
    console.log(clientCode);
  }, [inspectionData]);
  // useEffect(() => {}, [inspectionData]);
  useEffect(() => {
    if (InspectionHeaderItem.PROCESSSEDDATE) {
      setInspectionDate(
        moment(InspectionHeaderItem.PROCESSSEDDATE).format("YYYY-MM-DD")
      );
    }
  }, [InspectionHeaderItem.PROCESSSEDDATE]);
  async function Save() {
    console.log("exitDate:", exitDate);
    console.log("exitcarriedBy:", exitcarriedBy);
    console.log("inspectionexitnarration:", inspectionexitnarration);
    console.log("inspectionexitcarrieddate:", inspectionexitcarrieddate);
    console.log(exitStatusValues);
    console.log(exitStatusNarrationValues);
    console.log(InspectionHeaderItem.CLIENTCODE);
    console.log(InspectionDetails);

    const headerTypeValue = InspectionType;
    const inspectionNoValue = InspectionHeaderItem.INSPECTIONNO;
    const leaseNumberValue = InspectionHeaderItem.LEASENUMBER;
    const selectedClientName = InspectionHeaderItem.CLIENTCODE;
    const narrationValue = InspectionHeaderItem.NARRATION;
    const dateValueValue = InspectionHeaderItem.PROCESSSEDDATE;
    const CarriedByvalue = InspectionHeaderItem.PROCESSEDBY;
    const SubnitNo = InspectionHeaderItem?.SUBUNITNO;
    const PropertyId = InspectionHeaderItem?.PROJECTID;
    const meterreading = InspectionHeaderItem?.METERREADING;

    console.log(headerTypeValue);
    console.log(inspectionNoValue);
    console.log(leaseNumberValue);
    console.log(selectedClientName);
    console.log(narrationValue);
    console.log(dateValueValue);
    console.log(CarriedByvalue);
    console.log(exitDate);
    console.log(exitcarriedBy);
    console.log(inspectionexitnarration);
    console.log(inspectionexitcarrieddate);
    console.log(DamageNarration);
    console.log(DamageAmount);
    console.log(DamagePaidBy);
    console.log(SubnitNo);
    console.log(PropertyId);
    console.log(meterreading);

    if (
      !exitDate ||
      !exitcarriedBy ||
      !inspectionexitnarration ||
      !inspectionexitcarrieddate ||
      typeof exitStatusNarrationValues !== "object" ||
      Object.keys(exitStatusNarrationValues).length === 0 ||
      Object.values(exitStatusNarrationValues).some((value) => value === "") ||
      typeof exitStatusValues !== "object" ||
      Object.keys(exitStatusValues).length === 0 ||
      Object.values(exitStatusValues).some((value) => value === "")
    ) {
      alert("Please fill in all the required fields.");
    } else {
      const headerTypeValue = InspectionType;
      const inspectionNoValue = InspectionHeaderItem.INSPECTIONNO;
      const leaseNumberValue = InspectionHeaderItem.LEASENUMBER;
      const selectedClientName = InspectionHeaderItem.CLIENTCODE;
      const narrationValue = InspectionHeaderItem.NARRATION;
      const dateValueValue = InspectionHeaderItem.PROCESSSEDDATE.slice(0, 10);
      const CarriedByvalue = InspectionHeaderItem.PROCESSEDBY;
      const SubnitNo = InspectionHeaderItem?.SUBUNITNO;
      const PropertyId = InspectionHeaderItem?.PROJECTID;
      console.log(PropertyId);
      console.log(headerTypeValue);
      console.log(inspectionNoValue);
      console.log(leaseNumberValue);
      console.log(selectedClientName);
      console.log(narrationValue);
      console.log(dateValueValue);
      console.log(CarriedByvalue);
      console.log(exitDate);
      console.log(exitcarriedBy);
      console.log(inspectionexitnarration);
      console.log(inspectionexitcarrieddate);
      console.log(DamageNarration);
      console.log(DamageAmount);
      console.log(DamagePaidBy);
      console.log(PropertyId);
      console.log(SubnitNo);

      //   // Save header
      const saveHeaderPromise = axios.post(
        `${BASEURL}/savepropertyinspectionheader`,
        {
          INSPECTIONTYPE: headerTypeValue,
          INSPECTIONNO: inspectionNoValue,
          INSPECTIONLEASENUMBER: leaseNumberValue,
          INSPECTIONCLIENT: selectedClientName,
          INSPECTIONNARRATION: narrationValue,
          INSPECTIONDATE: moment(dateValueValue).format("YYYY/MM/DD"),
          INSPECTIONCARRIEDOUTBY: CarriedByvalue,
          EXITDATE: moment(exitDate).format("YYYY/MM/DD"),
          EXITCARRIEDBY: exitcarriedBy,
          INSPECTIONEXITNARRATION: inspectionexitnarration,
          INSPECTIONEXITCARRIEDDATE: moment(inspectionexitcarrieddate).format(
            "YYYY/MM/DD"
          ),
          DAMAGENARRATION: DamageNarration,
          DAMAGEAMOUNT: Number(DamageAmount),
          DAMAGETOBEPAIDBYTENANT: Number(DamagePaidBy),
          PROPERTYID: PropertyId,
          SUBUNITNO: SubnitNo,
          METERREADING: meterreading,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      //   // Save details
      const saveDetailsPromises = InspectionDetails.map((item) => {
        if (
          item.INSPECTIONSTATUSEXIT === null ||
          item.INSPECTIONSTATUSEXIT === ""
        ) {
          item.INSPECTIONSTATUSEXIT =
            exitStatusValues[item.INSPECTIONITEM] !== ""
              ? exitStatusValues[item.INSPECTIONITEM]
              : "";
        }
        if (item.ITEMNARRATIONEXIT === null || item.ITEMNARRATIONEXIT === "") {
          item.ITEMNARRATIONEXIT =
            exitStatusNarrationValues[item.INSPECTIONITEM] !== ""
              ? exitStatusNarrationValues[item.INSPECTIONITEM]
              : "";
        }
        console.log(item.ITEMNARRATIONEXIT);
        console.log(item.INSPECTIONSTATUSEXIT);

        const exitStatusExit = exitStatusValues[item.INSPECTIONITEM] || "";
        const itemNarrationExit =
          exitStatusNarrationValues[item.INSPECTIONITEM] || "";

        return axios.post(
          `${BASEURL}/savepropertyinspectiondetails`,
          {
            INSPECTIONTYPE: "EXIT",
            INSPECTIONNO: item.INSPECTIONNO,
            INSPECTIONAREA: item.INSPECTIONAREA,
            INSPECTIONITEM: item.INSPECTIONITEM,
            INSPECTIONITEMSTATUS: item.INSPECTIONSTATUS,
            INSPECTIONITEMNARRATION: item.ITEMNARRATION,
            EXITSTATUS: exitStatusExit,
            EXITSTATUSNARRATION: itemNarrationExit,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      // Wait for both requests to complete
      Promise.all([saveHeaderPromise, ...saveDetailsPromises])
        .then((responses) => {
          console.log(responses);
          alert("Header and Details saved successfully!");
        })
        .catch((error) => {
          console.error("Error saving Header and Details:", error);
          alert("Error saving Header and Details");
        });
    }

    // const inspectionNoValue = EditableInspctionNo || InspectionNo.NEXTNO;
    console.log(
      InspectionHeaderItem?.INSPECTIONNO,
      inspectionDocument,
      userState?.userCode,
      inspectionDocumentNarration
    );

    const uploadImage = async (file) => {
      const formData = new FormData();
      formData.append("imageValue", file);
      const response = await axios.post(
        "https://marstrackbackend.azurewebsites.net/fileupload/uploadImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data; // Assuming the response contains the string you need
    };

    // Upload each file and retrieve their respective strings
    const ImageString = await uploadImage(inspectionDocument);

    // const formData = new FormData();

    // formData.append("INSPECTIONNO", InspectionHeaderItem?.INSPECTIONNO);
    // formData.append("INSPECTIONDOCUMENTNUMBER", inspectionDocumentNumber);
    // formData.append(
    //   "INSPECTIONDOCUMENTATTACHEDNARRATION",
    //   inspectionDocumentNarration
    // );
    // formData.append("INSPECTIONDOCUMENTATTACHEDBY", userState?.userCode);

    // formData.append("imageValue", inspectionDocument);

    axios
      .post(
        `${BASEURL}/uploadleaseinspectionDocuments`,
        {
          INSPECTIONNO: InspectionHeaderItem?.INSPECTIONNO
            ? InspectionHeaderItem?.INSPECTIONNO
            : "",
          INSPECTIONDOCUMENTNUMBER: inspectionDocumentNumber
            ? inspectionDocumentNumber
            : "",
          INSPECTIONDOCUMENTATTACHED: ImageString ? ImageString : "",
          INSPECTIONDOCUMENTATTACHEDNARRATION: inspectionDocumentNarration
            ? inspectionDocumentNarration
            : "",
          INSPECTIONDOCUMENTATTACHEDBY: userState?.userCode
            ? userState?.userCode
            : "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleExitDateChange = (e) => {
    const selectedExitDate = e.target.value;
    console.log(inspectionDate);
    console.log(selectedExitDate);
    if (inspectionDate && selectedExitDate < inspectionDate) {
      // Show an error message or handle the invalid date as needed
      alert("Exit date must be greater than inspection date");
    } else {
      // Valid exit date, update the state
      setexitDate(selectedExitDate);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="elctric__page">
        <MenuNavbar />
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between mr-5">
                <h1 className="header__of__page">
                  {menu ? menu : "Edit Inspection Details"}
                </h1>
                {/* <aside className="button__load__off__main"> */}

                <div className=" flex gap-5 m-2">
                  <button
                    className="button-50 "
                    onClick={() => {
                      Save();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="button-50 "
                    onClick={() => {
                      navigate("/PENDINGEXITINSPECTIONDETAILS");
                    }}
                  >
                    Back
                  </button>
                </div>
                {/* </aside> */}
              </header>
              <section className="section__newgarage">
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">INSPECTION TYPE</label>
                    </p>
                    <div>
                      <div className=" text-red-600 font-bold">
                        {InspectionType}{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">INSPECTION NO</label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {InspectionHeaderItem?.INSPECTIONNO}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">PROJECT NAME</label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {InspectionHeaderItem?.PROJECTNAME}
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">SUB UNIT NO</label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {InspectionHeaderItem?.SUBUNITNO}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">INSPECTION CLIENT </label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      <div
                        style={{ textTransform: "uppercase" }}
                        className=" text-red-600 font-bold"
                      >
                        {InspectionHeaderItem?.CLIENTNAME}
                      </div>
                    </div>
                  </div>

                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">
                        ENTRY INSPECTION DATE{" "}
                      </label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {InspectionHeaderItem?.PROCESSSEDDATE
                        ? moment(InspectionHeaderItem?.PROCESSSEDDATE).format(
                            "DD/MM/YYYY"
                          )
                        : "N/A"}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">
                        INSPECTION CARRIED OUT BY{" "}
                      </label>
                    </p>
                    <div
                      style={{ textTransform: "uppercase" }}
                      className=" text-red-600 font-bold"
                    >
                      {InspectionHeaderItem?.PROCESSEDBY}
                    </div>
                  </div>

                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">OUTSTANDING AMOUNT</label>
                    </p>
                    <div
                      className="text-red-600 font-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {Outstandingamt?.AMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label>EXIT DATE</label>
                    </p>
                    <div>
                      <input
                        style={{ width: "200px" }}
                        type="date"
                        value={exitDate}
                        onChange={handleExitDateChange}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">EXIT CARRIED BY</label>
                    </p>
                    {/* <input
                      //    value={narration}
                      onChange={(e) => setexitcarriedBy(e.target.value)}
                      //    className="ml-4 text-red-600 font-bold"
                    ></input> */}
                    <div style={{ zIndex: 500 }}>
                      <Select
                        // value={{
                        //   value: CarriedByvalue,
                        //   label: CarriedByvalue,
                        // }}
                        onChange={(e) => setexitcarriedBy(e.value)}
                        className=""
                        options={Carriedoutby.map((item) => ({
                          value: item["EMPLOYEE NAME"],
                          label: item["EMPLOYEE NAME"],
                        }))}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">
                        INSPECTION EXIT CARRIED DATE
                      </label>
                    </p>
                    <div>
                      <input
                        type="date"
                        style={{ width: "200px" }}
                        //    value={narration}
                        onChange={(e) =>
                          setinspectionexitcarrieddate(e.target.value)
                        }
                        //    className="ml-4 text-red-600 font-bold"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">
                        INSPECTION EXIT NARRATION
                      </label>
                    </p>
                    <textarea
                      class="textmax__width border-solid border-black border-[1px] min-w-[270px] max-w-[270px] min-h-[60px] max-h-[60px]"
                      onChange={(e) =>
                        setinspectionexitnarration(e.target.value)
                      }
                    ></textarea>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">DAMAGE AMOUNT</label>
                    </p>
                    <div>
                      <input
                        type="number"
                        style={{ width: "200px" }}
                        //    value={narration}
                        onChange={(e) => setDamageAmount(e.target.value)}
                        //    className="ml-4 text-red-600 font-bold"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">
                        DAMAGE TO BE PAID BY TENANT
                      </label>
                    </p>
                    <div>
                      <select
                        type=""
                        style={{ width: "200px" }}
                        //    value={narration}
                        onChange={(e) => setDamagePaidBy(e.target.value)}
                        className="ml-4  border rounded-md border-black"
                      >
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  {/* <div>
                    <p style={{ width: "250px" }}>
                      <label className="garage_id_ ">
                        INSPECTION NARRATION
                      </label>
                    </p>
                    <div
                      className=" text-red-600 font-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {InspectionHeaderItem?.NARRATION}
                    </div>
                  </div> */}
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">DAMAGE NARRATION</label>
                    </p>
                    <div>
                      <textarea
                        // type="date"
                        class="textmax__width border-solid border-black border-[1px] min-w-[270px] max-w-[270px] min-h-[60px] max-h-[60px] mr-8"
                        style={{ width: "200px" }}
                        //    value={narration}
                        onChange={(e) => setDamageNarration(e.target.value)}
                        //    className="ml-4 text-red-600 font-bold"
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">INSPECTION DOCUMENT</label>
                    </p>
                    <div>
                      <input
                        type="file"
                        className="font-bold"
                        onChange={handleFileChange} // Handle file selection
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">
                        DOCUMENT ATTACHED NARRATION
                      </label>
                    </p>
                    <textarea
                      value={inspectionDocumentNarration}
                      onChange={(e) =>
                        setInspectionDocumentNarration(e.target.value)
                      }
                      class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[80px] max-h-[80px]"
                    ></textarea>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  {" "}
                  {preview && (
                    <div className="mt-4">
                      <p className="font-bold">Preview:</p>
                      <img
                        src={preview}
                        alt="Preview"
                        style={{
                          width: "300px",
                          height: "auto",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  )}
                </aside>
              </section>
            </main>
          </div>
        </div>
        <div className="service_detials">
          <table className="table_1">
            <thead style={{ zIndex: 40 }}>
              <tr>
                <td>INSPECTION AREA</td>
                <td>INSPECTION&nbsp;ITEMS</td>
                <td style={{ width: "200px" }}>MOVE-OUT</td>
                {/* <td>MOVE-OUT</td> */}
                <td>INSPECTION&nbsp;ITEM&nbsp;NARRATION</td>
                <td>EXIT STATUS</td>
                <td>EXIT STATUS NARRATION</td>
              </tr>
            </thead>
            {InspectionDetails.map((item, index) => (
              <tbody>
                <td style={{ padding: "10px" }}>
                  {item.INSPECTIONAREA ? item.INSPECTIONAREA : "N/A"}
                </td>
                <td style={{ padding: "10px" }}>
                  {item.INSPECTIONITEM ? item.INSPECTIONITEM : "N/A"}
                </td>
                <td style={{ padding: "10px" }}>
                  <div style={{ width: "250px", zIndex: 500 }}>
                    <div style={{ width: "250px", zIndex: 500 }}>
                      <Select
                        value={{
                          label: item?.INSPECTIONSTATUS,
                          value: item?.INSPECTIONSTATUS,
                        }}
                        // onChange={(selectedOptions) =>
                        //   handleMoveInChange(index, selectedOptions)
                        // }
                      />
                    </div>
                  </div>
                </td>
                {/* <td style={{ padding: "10px" }}>
                      <select
                        style={{
                          border: "1px solid",
                        }}
                        type="text"
                        className="h-[35px]"
                        onChange={(event) => handleStatusChange(index, event)}
                      >
                        <option value="select"></option>
                        <option>WORKING ORDER</option>

                        <option>DEFECT</option>
                      </select>
                    </td> */}
                <td style={{ padding: "10px" }}>
                  <textarea
                    class="textmax__width border-solid border-black border-[1px] min-w-[200px] max-w-[200px] min-h-[50px] max-h-[50px]"
                    // onChange={(event) => handleNarrationChange(index, event)}
                  >
                    {item?.ITEMNARRATION}
                  </textarea>
                </td>
                <td>
                  <input
                    onChange={(e) =>
                      handleExitStatusChange(
                        item?.INSPECTIONITEM,
                        e.target.value
                      )
                    }
                  ></input>
                </td>
                <td>
                  <textarea
                    class="mx-1 textmax__width border-solid border-black border-[1px] min-w-[200px] max-w-[200px] min-h-[50px] max-h-[50px]"
                    onChange={(e) =>
                      handleExitStatusNarrationChange(
                        item?.INSPECTIONITEM,
                        e.target.value
                      )
                    }
                  ></textarea>
                </td>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default EditInspectionDetails;
