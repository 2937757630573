import React, { useEffect, useState } from "react";
import axios, { toFormData } from "axios";
import { useDispatch, useSelector } from "react-redux";

import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
const ClientMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [isOpen, setIsOpen] = useState(false);
  const state = useSelector((state) => state.changeTheState);
  console.log(state);
  const UserEmail = state?.user ? state?.user?.emailId?.split("@")[0] : "N/A";
  console.log(UserEmail);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const [search, setSearch] = useState("");
  const [ClientMaster, setClientMaster] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [filtHistoricCashItem, setfiltHistoricCashItem] = useState([]);
  const [Type, setType] = useState("new");
  const [approvalFilter, setApprovalFilter] = useState("");

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [ClientCode, setClientCode] = useState("");
  const [ClientName, setClientName] = useState("");
  const [Password, setPassword] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [Deposit, setDeposit] = useState("");
  const [Project, setProject] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [DOB, setDOB] = useState("");
  const [EmergencyContactName, setEmergencyContactName] = useState("");
  const [EmergencyContactPhone, setEmergencyContactPhone] = useState("");
  const [GuardianContactName, setGuardianContactName] = useState("");
  const [GuardianEmail, setGuardianEmail] = useState("");
  const [GuardianBox, setGuardianBox] = useState("");
  const [GuardianPhysicalAddress, setGuardianPhysicalAddress] = useState("");
  const [GuardianHomeAddress, setGuardianHomeAddress] = useState("");
  const [GuardianName, setGuardianName] = useState("");
  const [GuardianIDPassport, setGuardianIDPassport] = useState("");
  const [StudentEmail, setStudentEmail] = useState("");
  const [StudentIDPassport, setStudentIDPassport] = useState("");
  const [StudentLastName, setStudentLastName] = useState("");
  const [StudentUniversity, setStudentUniversity] = useState("");
  const [CreatedBy, setCreatedBy] = useState("");
  const [popClientName, setpopClientName] = useState("");
  const [popClientCode, setpopClientcode] = useState("");
  const [popClientProperty, setpopClientProperty] = useState("");
  const [popUnitNo, setpopUnitNo] = useState("");
  const [Deposite, setDeposite] = useState("");
  const [totalCR, setTotalCR] = useState(0);
  const [totalDR, setTotalDR] = useState(0);
  const [totalcummualtive, settotalcumaltive] = useState(0);
  const [clientCatagoryList, setClientCategoryList] = useState([]);
  const [clientPostalCode, setCleintPostalCode] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [cleintMobile, setClientMobile] = useState("");
  const [clientCashorCredit, setClientCashorCredit] = useState("CASH");
  const [clientType, setClientType] = useState(0);
  const [clientCategory, setClientCategory] = useState("");
  const [picture, setPicture] = useState(null);
  const [dispPicture, setDispPicture] = useState("");
  const [ImagineValue, setImagineValue] = useState("");
  const [fileUpload, setFileUpload] = useState(null);

  useEffect(() => {
    masterData();
  }, []);
  useEffect(() => {
    // Calculate totals whenever filteredData changes
    let totalCR = 0;
    let totalDR = 0;
    let totalcummualtive = 0;

    filtHistoricCashItem.forEach((item) => {
      totalDR += parseFloat(item?.DR);
      totalCR += parseFloat(item?.CR);
      totalcummualtive += parseFloat(item?.CUMMUALTIVE);
    });

    setTotalCR(totalCR);
    setTotalDR(totalDR);
    settotalcumaltive(totalcummualtive);
    console.log(totalCR);
    console.log(totalDR);
  }, [filtHistoricCashItem]);
  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = ClientMaster.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch = searchData.every((term) => {
      return [
        "CLIENTCODE",
        "CLIENTNAME",
        "CLIENTEMAIL",
        "PROJECT",
        "UNIT",
        "CONTACTNUMBER",
        "EMERGENCYCONTACTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const Date = item.LEASESTARTDATE;
    // if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
    //   return true;
    // }
    // const dateMatch =
    //   (!fromDateFilter || Date >= fromDateFilter) &&
    //   (!toDateFilter || Date <= toDateFilter);
    const approvalMatch =
      approvalFilter === ""
        ? true
        : approvalFilter === "APPROVED"
        ? item.APPROVED === "APPROVED"
        : approvalFilter === "Rejected"
        ? item.APPROVED === "REJECTED"
        : approvalFilter === "Not Approved"
        ? item.APPROVED === null
        : true;
    return textMatch && approvalMatch;
    // && dateMatch;
  });
  console.log(filteredData);

  function masterData() {
    setisloading(true);

    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setClientMaster(response.data);
        console.log(response.data);
      });
    axios
      .get(`${BASEURL}/getclientCategoryList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setClientCategoryList(response.data);
        console.log(response.data);
      });

    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectid(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
  }

  function Popdetails(item) {
    setpopClientName(item?.CLIENTNAME ? item?.CLIENTNAME : "N/A");
    setpopClientcode(item?.CLIENTCODE ? item?.CLIENTCODE : "N/A");
    setpopClientProperty(item?.PROJECT ? item?.PROJECT : "N/A");
    setDeposite(item?.DEPOSIT ? item?.DEPOSIT : "N/A");
    setpopUnitNo(item?.UNIT ? item?.UNIT : "N/A");
    setisloading(true);
    const startdate =
      fromDateFilter.slice(8, 10) +
      "/" +
      fromDateFilter.slice(5, 7) +
      "/" +
      fromDateFilter.slice(0, 4);
    const enddate =
      toDateFilter.slice(8, 10) +
      "/" +
      toDateFilter.slice(5, 7) +
      "/" +
      toDateFilter.slice(0, 4);
    console.log(item?.CLIENTCODE);

    console.log(enddate);
    console.log(startdate);
    axios
      .post(
        `${BASEURL}/getClientstatementDetails`,
        {
          CLIENTCODE: item?.CLIENTCODE,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )

      .then((response) => {
        console.log(response.data);
        setfiltHistoricCashItem(response.data);
        setisloading(false);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching list of inventory:", error);
      });
  }

  if (isloading) {
    return <Loading />;
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const formatDate = (dateString) => {
    if (!dateString) return ""; // handle empty date
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth returns zero-based month
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleEdit = (item) => {
    console.log(item);
    console.log("Client Code:", item.CLIENTCODE);
    console.log("Client Name:", item.CLIENTNAME);
    console.log("Client Email:", item.CLIENTEMAIL);
    console.log("Password:", item.PASSWORD);
    console.log("Deposit:", item.DEPOSIT);
    console.log("Project:", item.PROJECT);
    console.log("Unit:", item.UNIT);
    console.log("Contact Number:", item.CONTACTNUMBER);
    console.log("Lease Start Date:", item.LEASESTARTDATE);
    console.log("Emergency Contact Name:", item.EMERGENCYCONTACTNAME);
    console.log("Emergency Contact Phone:", item.EMERGENCYCONTACTPHONE);
    console.log("Student Email:", item.STUDENTEMAIL);
    console.log("Student ID/Passport:", item.STUDENTIDPASSPORT);
    console.log("Guardian Contact Name:", item.GUARDIANCONTACTNAME);
    console.log("Guardian Contact Phone:", item.GUARDIANCONTACTPHONE);
    console.log("Guardian Email:", item.GUARDIANEMAIL);
    console.log("Guardian Box:", item.GUARDIANBOX);
    console.log("Guardian Physical Address:", item.GUARDIANPHYSICALADDRESS);
    console.log("Guardian Home Address:", item.GUARDIANHOMEADDRESS);
    console.log("Guardian Name:", item.GUARDIANNAME);
    console.log("Guardian ID/Passport:", item.GUARDIANIDPASSPORT);
    console.log("Student Last Name:", item.STUDENTLASTNAME);
    console.log("Student University:", item.STUDENTUNIVERSITY);
    console.log("Student Mail:", item.STUDENTEMAIL);
    // console.log("DOB", item.DOB.format("YYYY/MM/DD"));
    setClientCode(item.CLIENTCODE || "");
    setClientName(item.CLIENTNAME || "");
    setClientEmail(item.CLIENTEMAIL || "");
    setPassword(item.PASSWORD || "");
    setDeposit(item.DEPOSIT || 0.0);
    setProject(item.PROJECT || "");
    // setUnit(item.UNIT || "");
    setContactNumber(item.CONTACTNUMBER || "");
    // setLeaseStartDate(item.LEASESTARTDATE || "");
    const dob = new Date(item.DOB);
    const formattedDOB =
      dob.getFullYear() + "/" + (dob.getMonth() + 1) + "/" + dob.getDate();
    console.log(formattedDOB);
    // setDOB(formattedDOB);
    setDOB(item.DOB ? item.DOB.split("/").reverse().join("-") : "");
    console.log(item.DOB ? item.DOB.split("/").reverse().join("-") : "");
    setEmergencyContactName(item.EMERGENCYCONTACTNAME || "");
    setEmergencyContactPhone(item.EMERGENCYCONTACTPHONE || "");
    setStudentEmail(item.STUDENTEMAIL || "");
    setStudentIDPassport(item.STUDENTIDPASSPORT || "");
    setGuardianContactName(item.GUARDIANCONTACTNAME || "");
    // setEmergencyContactPhone(item.GUARDIANCONTACTPHONE || "");
    setGuardianEmail(item.GUARDIANEMAIL || "");
    setGuardianBox(item.GUARDIANBOX || "");
    setGuardianPhysicalAddress(item.GUARDIANPHYSICALADDRESS || "");
    setGuardianHomeAddress(item.GUARDIANHOMEADDRESS || "");
    setGuardianName(item.GUARDIANNAME || "");
    setGuardianIDPassport(item.GUARDIANIDPASSPORT || "");
    setStudentLastName(item.STUDENTLASTNAME || "");
    setStudentUniversity(item.STUDENTUNIVERSITY || "");
    setStudentEmail(item.STUDENTEMAIL || "");
    setClientAddress(item.ADDRESSS || "");
    setCleintPostalCode(item.POSTALCODE || "");
    setClientMobile(item.MOBILE || "");
    setClientCashorCredit(item.CASHCREDIT || "");
    setClientType(item.CLIENTTYPE === true ? 1 : 0 || 0);
    setClientCategory(item.CLIENTCATEGORY || "");
    setDispPicture(null);
    setImagineValue(item.IMAGENAME || "");
  };
  console.log(picture);

  function handlePictureUpload(e) {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check file size
      if (file.size <= 500 * 1024) {
        // 500KB in bytes
        setPicture(file);
        console.log(file);
        console.log(file);

        const imageURL = URL.createObjectURL(file);
        setDispPicture(imageURL);
      } else {
        // Notify user if the file exceeds 500KB
        alert("Please select an image file that is 500KB or smaller.");
        // Clear the input field
        e.target.value = null;
      }
    }
  }

  async function SaveClient() {
    const birthdate =
      DOB.slice(8, 10) + "/" + DOB.slice(5, 7) + "/" + DOB.slice(0, 4);
    console.log(birthdate);
    console.log(Type);
    if (!ClientCode) {
      alert("Please Provide the ClientCode");
      return;
    }
    if (!ClientName) {
      alert("Please Provide the ClientName");
      return;
    }
    if (!Password) {
      alert("Please Provide the Password");
      return;
    }
    if (!ClientEmail) {
      alert("Please Provide the ClientEmail");
      return;
    }
    if (!Deposit) {
      alert("Please Provide the Deposit");
      return;
    }

    if (!Project) {
      alert("Please Select the Project");
      return;
    }

    if (!ContactNumber) {
      alert("Please Select the ContactNumber");
      return;
    }
    if (!DOB) {
      alert("Please Select the DOB");
      return;
    }
    if (!EmergencyContactName) {
      alert("Please Select the EmergencyContactName");
      return;
    }
    if (!EmergencyContactPhone) {
      alert("Please Select the EmergencyContactPhone");
      return;
    }
    if (!GuardianContactName) {
      alert("Please Select the GuardianContactName");
      return;
    }
    if (!GuardianEmail) {
      alert("Please Select the GuardianEmail");
      return;
    }
    if (!GuardianBox) {
      alert("Please Select the GuardianBox");
      return;
    }
    if (!GuardianPhysicalAddress) {
      alert("Please Select the GuardianPhysicalAddress");
      return;
    }
    if (!GuardianHomeAddress) {
      alert("Please Select the GuardianHomeAddress");
      return;
    }
    if (!GuardianName) {
      alert("Please Select the GuardianName");
      return;
    }
    if (!GuardianIDPassport) {
      alert("Please Select the GuardianIDPassport");
      return;
    }
    if (!StudentEmail) {
      alert("Please Select the StudentEmail");
      return;
    }
    if (!StudentIDPassport) {
      alert("Please Select the StudentIDPassport");
      return;
    }
    if (!StudentLastName) {
      alert("Please Select the StudentLastName");
      return;
    }
    if (!StudentUniversity) {
      alert("Please Select the StudentUniversity");
      return;
    }
    if (!UserEmail) {
      alert("Please Select the UserEmail");
      return;
    }

    // const formData = new FormData();

    console.log("CLIENTCODE:", ClientCode);
    console.log("CLIENTNAME:", ClientName);
    console.log("PASSWORD:", Password);
    console.log("CLIENTEMAIL:", ClientEmail);
    console.log("DEPOSIT:", Deposit);
    console.log("PROJECT:", Project);
    console.log("CONTACTNUMBER:", ContactNumber);
    console.log("DOB:", DOB);
    console.log("EMERGENCYCONTACTNAME:", EmergencyContactName);
    console.log("EMERGENCYCONTACTPHONE:", EmergencyContactPhone);
    console.log("GUARDIANCONTACTNAME:", GuardianContactName);
    console.log("GUARDIANEMAIL:", GuardianEmail);
    console.log("GUARDIANBOX:", GuardianBox);
    console.log("GUARDIANPHYSICALADDRESS:", GuardianPhysicalAddress);
    console.log("GUARDIANHOMEADDRESS:", GuardianHomeAddress);
    console.log("GUARDIANNAME:", GuardianName);
    console.log("GUARDIANIDPASSPORT:", GuardianIDPassport);
    console.log("STUDENTEMAIL:", StudentEmail);
    console.log("STUDENTIDPASSPORT:", StudentIDPassport);
    console.log("STUDENTLASTNAME:", StudentLastName);
    console.log("STUDENTUNIVERSITY:", StudentUniversity);
    console.log("CREATEDBY:", UserEmail);

    // Append all form fields to the FormData object
    // formData.append("CLIENTCODE", ClientCode);
    // formData.append("CLIENTNAME", ClientName);
    // formData.append("PASSWORD", Password);
    // formData.append("CLIENTEMAIL", ClientEmail);
    // formData.append("DEPOSIT", Deposit);
    // formData.append("PROJECT", Project);
    // formData.append("CONTACTNUMBER", ContactNumber);
    // formData.append("DOB", birthdate);
    // formData.append("EMERGENCYCONTACTNAME", EmergencyContactName);
    // formData.append("EMERGENCYCONTACTPHONE", EmergencyContactPhone);
    // formData.append("GUARDIANCONTACTNAME", GuardianContactName);
    // formData.append("GUARDIANEMAIL", GuardianEmail);
    // formData.append("GUARDIANBOX", GuardianBox);
    // formData.append("GUARDIANPHYSICALADDRESS", GuardianPhysicalAddress);
    // formData.append("GUARDIANHOMEADDRESS", GuardianHomeAddress);
    // formData.append("GUARDIANNAME", GuardianName);
    // formData.append("GUARDIANIDPASSPORT", GuardianIDPassport);
    // formData.append("STUDENTEMAIL", StudentEmail);
    // formData.append("STUDENTIDPASSPORT", StudentIDPassport);
    // formData.append("STUDENTLASTNAME", StudentLastName);
    // formData.append("STUDENTUNIVERSITY", StudentUniversity);
    // formData.append("CREATEDBY", UserEmail);
    // formData.append("CLIENTCASHCREDIT", clientCashorCredit);
    // formData.append("CLIENTPOSTALCODE", clientPostalCode);
    // formData.append("CLIENTADDRESS", clientAddress);
    // formData.append("CLIENTMOBILE", cleintMobile);
    // formData.append("CLIENTTYPE", clientType);
    // formData.append("CLIENTCATEGORY", clientCategory);

    // Append the file (image)
    // formData.append("imageValue", picture ? picture : ImagineValue); // Attach the file

    const uploadImage = async (file) => {
      const formData = new FormData();
      formData.append("imageValue", file);
      const response = await axios.post(
        "https://marstrackbackend.azurewebsites.net/fileupload/uploadImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data; // Assuming the response contains the string you need
    };

    // Upload each file and retrieve their respective strings
    let ImageString = "";
    if (picture) {
      ImageString = await uploadImage(picture);
    }

    setisloading(true);

    // Send the formData using axios
    axios
      .post(
        `${BASEURL}/saveclient/${Type}`,
        {
          CLIENTCODE: ClientCode,
          CLIENTNAME: ClientName,
          CLIENTCASHCREDIT: clientCashorCredit,
          CLIENTPOSTALCODE: clientPostalCode,
          CLIENTADDRESS: clientAddress,
          CLIENTMOBILE: cleintMobile,
          CLIENTTYPE: clientType,
          CLIENTCATEGORY: clientCategory,
          PASSWORD: Password,
          CLIENTEMAIL: ClientEmail,
          DEPOSIT: Deposit,
          PROJECT: Project,
          CONTACTNUMBER: ContactNumber,
          DOB: birthdate,
          EMERGENCYCONTACTNAME: EmergencyContactName,
          EMERGENCYCONTACTPHONE: EmergencyContactPhone,
          GUARDIANCONTACTNAME: GuardianContactName,
          GUARDIANEMAIL: GuardianEmail,
          GUARDIANBOX: GuardianBox,
          GUARDIANPHYSICALADDRESS: GuardianPhysicalAddress,
          GUARDIANHOMEADDRESS: GuardianHomeAddress,
          GUARDIANNAME: GuardianName,
          GUARDIANIDPASSPORT: GuardianIDPassport,
          STUDENTEMAIL: StudentEmail,
          STUDENTIDPASSPORT: StudentIDPassport,
          STUDENTLASTNAME: StudentLastName,
          STUDENTUNIVERSITY: StudentUniversity,
          CREATEDBY: UserEmail,
          CLIENTIMAGE: ImageString ? ImageString : ImagineValue,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
            "Content-Type": "multipart/form-data", // Required for file upload
          },
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data.message);
        alert(response.data.message);
        setisloading(false);
        setType("new");
        window.location.reload();
        ClearInput();

        masterData();
      })
      .catch((error) => {
        setisloading(false);

        console.error("Error saving client:", error);
        console.log(error.response);
        alert(
          error?.response?.data?.message === undefined
            ? "internal server error"
            : error?.response?.data?.message
        );
        // window.location.reload();
      });
  }
  function ClearInput() {
    setClientCode("");
    setClientName("");
    setClientEmail("");
    setPassword("");
    setDeposit(0.0);
    setProject("");
    // setUnit(item.UNIT || "");
    setContactNumber("");
    // setLeaseStartDate(item.LEASESTARTDATE || "");

    setEmergencyContactName("");
    setEmergencyContactPhone("");
    setStudentEmail("");
    setStudentIDPassport("");
    setGuardianContactName("");
    // setEmergencyContactPhone(item.GUARDIANCONTACTPHONE || "");
    setGuardianEmail("");
    setGuardianBox("");
    setGuardianPhysicalAddress("");
    setGuardianHomeAddress("");
    setGuardianName("");
    setGuardianIDPassport("");
    setStudentLastName("");
    setStudentUniversity("");
    setStudentEmail("");
    setDOB("");
  }

  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
        <div>
          <div className="div">
            <div className="center-container_1">
              <div className="form-container_1  ">
                <main className="form_info ">
                  <header className="header__of__main lg:flex justify-between px-3 items-center">
                    {menu ? (
                      <h1 className="header__of__page">
                        {" "}
                        {menu ? menu : "Client Master"}
                      </h1>
                    ) : (
                      <h1 className="header__of__page"> CLIENT MASTER</h1>
                    )}

                    <div>
                      <button
                        className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                        onClick={() => {
                          navigate("/ApprovedClients");
                        }}
                      >
                        Approved Clients
                      </button>
                      <button
                        className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                        onClick={() => {
                          navigate("/UnapprovedClients");
                        }}
                      >
                        Unapproved Clients
                      </button>
                      <button
                        className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                        onClick={() => {
                          navigate("/RejectedClients");
                        }}
                      >
                        Rejected Clients
                      </button>
                    </div>
                  </header>
                  <section className="section__newgarage">
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT CODE </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            name="clientcode"
                            readOnly={Type == "update"}
                            value={ClientCode || ""}
                            type="text"
                            onChange={(e) => {
                              setClientCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT NAME </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            readOnly={Type == "update"}
                            value={ClientName || ""}
                            name="clientname"
                            type="text"
                            onChange={(e) => {
                              setClientName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT EMAIL </label>
                        </p>
                        <div>
                          <input
                            value={ClientEmail || ""}
                            name="clientemail"
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setClientEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">PASSWORD </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            value={Password || ""}
                            name="password"
                            type="text"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">
                            CLIENT POSTAL CODE{" "}
                          </label>
                        </p>
                        <div>
                          <input
                            name="clientemail"
                            className="input_1"
                            type="text"
                            value={clientPostalCode || ""}
                            onChange={(e) => {
                              setCleintPostalCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT ADDRESS </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            name="password"
                            type="text"
                            value={clientAddress || ""}
                            onChange={(e) => {
                              setClientAddress(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT MOBILE </label>
                        </p>
                        <div>
                          <input
                            name="clientemail"
                            className="input_1"
                            type="text"
                            value={cleintMobile || ""}
                            onChange={(e) => {
                              setClientMobile(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">CASH/CREDIT </label>
                        </p>
                        <div>
                          {/* <select
                            onChange={(e) => {
                              setClientCashorCredit(e.target.value);
                            }}
                          >
                            <option value="CASH">CASH</option>
                            <option value="CREDIT">CREDIT</option>
                          </select> */}
                          <Select
                            value={{
                              label: clientCashorCredit,
                              value: clientCashorCredit,
                            }}
                            onChange={(e) => {
                              setClientCashorCredit(e.value);
                            }}
                            options={[
                              { label: "CASH", value: "CASH" },
                              { label: "CREDIT", value: "CREDIT" },
                            ]}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT TYPE </label>
                        </p>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="nationality"
                              value="local"
                              checked={clientType === 0}
                              onChange={() => {
                                setClientType(0);
                              }}
                            />
                            Local
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="nationality"
                              value="foreigner"
                              checked={clientType === 1}
                              onChange={() => {
                                setClientType(1);
                              }}
                            />
                            Foreigner
                          </label>
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">CLIENT CATEGORY </label>
                        </p>
                        <div>
                          <Select
                            onChange={(e) => {
                              setClientCategory(e.value);
                            }}
                            value={{
                              label: clientCategory,
                              value: clientCategory,
                            }}
                            options={clientCatagoryList.map((item) => ({
                              value: item?.CLIENTCATEGORY,
                              label: item?.CLIENTCATEGORY,
                            }))}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">DEPOSIT </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            name="deposit"
                            type="number"
                            value={Deposit}
                            onChange={(e) => {
                              setDeposit(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">PROJECT </label>
                        </p>
                        <div>
                          <Select
                            name="project"
                            // value={FormData.clientname || ""}
                            styles={customStyless}
                            onChange={(selectedOption) => {
                              if (selectedOption.value === "") {
                                alert("Kindly Supply proper Project Name");
                                setProject("");
                              } else {
                                setProject(selectedOption.value);
                                // handleProject(selectedOption);
                              }
                            }}
                            value={
                              Project
                                ? {
                                    label: Project,
                                    value: Project,
                                  }
                                : { label: "", value: "" }
                            }
                            options={[
                              { value: "", label: "" },
                              ...listofprojectid.map((item) => ({
                                value: item.PROJECTNAME,
                                label: item.PROJECTNAME,
                              })),
                            ]}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">CONTACT NUMBER </label>
                        </p>
                        <div>
                          <input
                            name="contactnumber"
                            value={ContactNumber || ""}
                            className="input_1"
                            type="number"
                            onChange={(e) => {
                              setContactNumber(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p>
                          <label className="garage_id_">DOB </label>
                        </p>
                        <div>
                          <input
                            name="dob"
                            className="input_1"
                            value={formatDate(DOB)}
                            type="date"
                            onChange={(e) => {
                              setDOB(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">
                            EMERGENCY CONTACT NAME{" "}
                          </label>
                        </p>
                        <div>
                          <input
                            name="emergencycontactname"
                            value={EmergencyContactName || ""}
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setEmergencyContactName(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <p>
                          <label className="garage_id_">
                            EMERGENCY CONTACT PHONE{" "}
                          </label>
                        </p>
                        <div>
                          <input
                            name="emergencycontactphone"
                            value={EmergencyContactPhone || ""}
                            className="input_1"
                            type="number"
                            onChange={(e) => {
                              setEmergencyContactPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">GUARDIAN CONTACT</label>
                        </p>
                        <div>
                          <input
                            name="guardiancontactname"
                            value={GuardianContactName || ""}
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setGuardianContactName(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <p>
                          <label className="garage_id_">GUARDIAN EMAIL</label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            name="guardianemail"
                            value={GuardianEmail || ""}
                            type="text"
                            onChange={(e) => {
                              setGuardianEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>

                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">GUARDIAN BOX</label>
                        </p>
                        <div>
                          <textarea
                            name="guardianbox"
                            value={GuardianBox || ""}
                            className="mx-1 textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[55px] max-h-[55px]"
                            onChange={(e) => {
                              setGuardianBox(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <p>
                          <label className="garage_id_">
                            GUARDIAN PHYSICAL ADDRESS{" "}
                          </label>
                        </p>
                        <div>
                          <textarea
                            value={GuardianPhysicalAddress || ""}
                            type="text"
                            className="mx-1 textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[55px] max-h-[55px]"
                            onChange={(e) => {
                              setGuardianPhysicalAddress(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">
                            GUARDIAN HOME ADDRESS
                          </label>
                        </p>
                        <div>
                          <textarea
                            value={GuardianHomeAddress || ""}
                            type="text"
                            className="mx-1 textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[55px] max-h-[55px]"
                            onChange={(e) => {
                              setGuardianHomeAddress(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <p>
                          <label className="garage_id_">GUARDIAN NAME </label>
                        </p>
                        <div>
                          <input
                            name="guardianname"
                            className="input_1"
                            type="text"
                            value={GuardianName || ""}
                            onChange={(e) => {
                              setGuardianName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">
                            GUARDIAN ID PASSPORT
                          </label>
                        </p>
                        <div>
                          <input
                            value={GuardianIDPassport || ""}
                            readOnly={Type == "update"}
                            name="guardiaidpassport"
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setGuardianIDPassport(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <p>
                          <label className="garage_id_">STUDENTE MAIL </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            value={StudentEmail || ""}
                            name="studentmail"
                            type="text"
                            onChange={(e) => {
                              setStudentEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">
                            STUDENT ID PASSPORT
                          </label>
                        </p>
                        <div>
                          <input
                            name="studentidpassport"
                            readOnly={Type == "update"}
                            value={StudentIDPassport || ""}
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setStudentIDPassport(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <p>
                          <label className="garage_id_">
                            STUDENT LAST NAME{" "}
                          </label>
                        </p>
                        <div>
                          <input
                            name="studentlastname"
                            value={StudentLastName || ""}
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setStudentLastName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="aside__new__garage PropertyUnitLeases__width">
                      <div>
                        <p>
                          <label className="garage_id_">
                            STUDENT UNIVERSITY
                          </label>
                        </p>
                        <div>
                          <input
                            name="studentuniversity"
                            className="input_1"
                            type="text"
                            value={StudentUniversity || ""}
                            onChange={(e) => {
                              setStudentUniversity(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <input
                          style={{ padding: "5px", display: "none" }}
                          id="file"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => {
                            handlePictureUpload(e);
                          }}
                        />
                        <div className="upload_file">
                          <label
                            className=" bg-blue-300 rounded p-1"
                            htmlFor="file"
                          >
                            Upload
                          </label>
                        </div>
                        <img
                          required
                          name="userImg"
                          style={{ fontFamily: "Cursive" }}
                          src={dispPicture ? dispPicture : ImagineValue}
                          alt="Upload image here "
                          className={dispPicture ? "img" : "profile_pic"}
                        />
                      </div>
                      {/* <div>
                        <p>
                          <label className="garage_id_">CREATED BY </label>
                        </p>
                        <div>
                          <input
                            className="input_1"
                            type="text"
                            onChange={(e) => {
                              setCreatedBy(e.target.value);
                            }}
                          />
                        </div>
                      </div> */}
                    </aside>
                    <div className="savebutton">
                      <button className="button" onClick={SaveClient}>
                        Save
                      </button>
                      <button className="button" onClick={ClearInput}>
                        New
                      </button>
                    </div>
                  </section>
                </main>
              </div>
            </div>
          </div>
          <article>
            <div className="lg:flex items-center my-2 mx-[20px]">
              <div className="lg:flex">
                <p className="lg:mx-2 text-[16px]">Search</p>
                <input
                  className="w-[300px] lg:mr-[50px] h-[30px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <p className="lg:mx-2 text-[16px]"> STATUS</p>
              <select
                id="approvalFilter"
                value={approvalFilter}
                onChange={(e) => setApprovalFilter(e.target.value)}
                className="approval-filter border-black border-[1px] border-solid"
              >
                <option value="">All</option>
                <option value="APPROVED">APPROVED</option>
                <option value="Not Approved">NOT APPROVED</option>
                <option value="Rejected">REJECTED</option>
              </select>
              {/* <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
                <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateFilterChange}
                  />
                </div>
                <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
                  <input
                    type="date"
                    value={toDateFilter}
                    onChange={handleToDateFilterChange}
                  />
                </div>
              </section> */}
            </div>
            <div className="  overflow-auto   p-[20px] for__table__width__full">
              <table className="table_1  table__size__reducer ">
                <thead>
                  <tr style={{ fontWeight: "400" }}>
                    <td>CLIENT CODE</td>
                    <td>CLIENT NAME</td>
                    <td>CLIENT EMAIL</td>
                    <td>PROJECT</td>
                    <td>UNIT</td>
                    <td className="text-right">CONTACT NUMBER</td>
                    <td>LEASE START DATE</td>
                    <td className="text-right">Days Left For Expiry</td>
                    <td>EMERGENCY CONTACT NAME</td>
                    <td className="text-right">EMERGENCY CONTACT PHONE</td>
                    <td>STUDENT EMAIL</td>
                    <td>STUDENT PASSPORT ID</td>
                    <td>IMAGE</td>
                    <td>EDIT</td>
                    <td>VIEW STATEMENT</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => {
                      const leaseStartDate = new Date(item?.LEASESTARTDATE);
                      const currentDate = new Date();
                      const timeDiff = Math.abs(currentDate - leaseStartDate);
                      const diffDays = Math.ceil(
                        timeDiff / (1000 * 60 * 60 * 24)
                      ); // Calculating difference in days

                      return (
                        <tr key={index} className="table-row">
                          <td>
                            {item?.CLIENTCODE ? item?.CLIENTCODE : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.CLIENTNAME ? item?.CLIENTNAME : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.CLIENTEMAIL ? item?.CLIENTEMAIL : "N/A"}{" "}
                          </td>
                          <td>{item?.PROJECT ? item?.PROJECT : "N/A"} </td>
                          <td>{item?.UNIT ? item?.UNIT : "N/A"} </td>
                          <td className="text-right">
                            {item?.CONTACTNUMBER ? item?.CONTACTNUMBER : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.LEASESTARTDATE
                              ? item?.LEASESTARTDATE.slice(8, 10) +
                                "/" +
                                item?.LEASESTARTDATE.slice(5, 7) +
                                "/" +
                                item?.LEASESTARTDATE.slice(0, 4)
                              : "N/A"}{" "}
                          </td>
                          <td className="text-right">
                            {" "}
                            {item?.LEASESTARTDATE ? diffDays : 0}
                          </td>{" "}
                          <td>
                            {item?.EMERGENCYCONTACTNAME
                              ? item?.EMERGENCYCONTACTNAME
                              : "N/A"}{" "}
                          </td>
                          <td className="text-right">
                            {item?.EMERGENCYCONTACTPHONE
                              ? item?.EMERGENCYCONTACTPHONE
                              : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.STUDENTEMAIL ? item?.STUDENTEMAIL : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.STUDENTIDPASSPORT
                              ? item?.STUDENTIDPASSPORT
                              : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.IMAGENAME ? (
                              <>
                                <img
                                  src={item?.IMAGENAME}
                                  className="w-[100px] h-70px]"
                                  alt=""
                                />
                              </>
                            ) : (
                              "N/A"
                            )}{" "}
                          </td>
                          <td style={{ fontSize: "20px", cursor: "pointer" }}>
                            {item.APPROVED !== "APPROVED" && (
                              <p
                                onClick={() => {
                                  handleEdit(item);
                                  scrollToSection();
                                  setType("update");
                                }}
                              >
                                <BiEditAlt />
                              </p>
                            )}
                          </td>
                          <td
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              Popdetails(item);
                              togglePopup();
                            }}
                          >
                            <IoDocumentAttachOutline />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </article>
        </div>

        {isOpen && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[93%] p-1 overflow-y-auto shadow-4xl rounded">
            <button
              className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
            <aside className="  overflow-auto m-[10px]  p-[20px]">
              <div className="flex gap-6 m-4 ">
                <span>
                  CLIENT CODE :{" "}
                  <span className="text-red-500 font-bold">
                    {popClientCode}
                  </span>
                </span>
                <span>
                  CLIENT NAME :{" "}
                  <span className="text-red-500 font-bold">
                    {popClientName}
                  </span>{" "}
                </span>
                <span>
                  CLIENT PROPERTY :{" "}
                  <span className="text-red-500 font-bold">
                    {popClientProperty}
                  </span>
                </span>

                <span>
                  UNIT :{" "}
                  <span className="text-red-500 font-bold">{popUnitNo}</span>
                </span>
                <span>
                  DEPOSIT :{" "}
                  <span className="text-red-500 font-bold">{Deposite}</span>
                </span>
              </div>

              <table
                className="table_1  for__table__width__full"
                style={{ fontSize: "12px" }}
              >
                <thead class="header-cell sticky top-0  z-0 text-sm">
                  <tr>
                    <td>SALE TYPE</td>
                    <td className="text-left">INVOICE DATE</td>
                    <td className="text-left">INVOICE NUMBER</td>
                    <td className="text-left">SERVICES</td>
                    <td className="text-right">DR</td>
                    <td className="text-right">CR </td>
                    {/* <td className="text-left">CURRENCY</td> */}
                    <td className="text-left">CUMULATIVE </td>
                    {/* <td className="text-right">DEPOSIT </td> */}
                  </tr>
                </thead>
                <tbody>
                  {filtHistoricCashItem.map((row, index) => (
                    <tr
                      key={row.PONO}
                      onClick={() => {
                        // handleCreditSaleClick(row);
                      }}
                      className={
                        index < filtHistoricCashItem.length
                          ? "border-b cursor-pointer table-row"
                          : ""
                      }
                    >
                      <td>{row.SALETYPE ? row.SALETYPE : ""}</td>
                      <td>
                        {row.INVOICEDATE
                          ? row?.INVOICEDATE
                            ? row?.INVOICEDATE.slice(8, 10) +
                              "/" +
                              row?.INVOICEDATE.slice(5, 7) +
                              "/" +
                              row?.INVOICEDATE.slice(0, 4)
                            : "N/A"
                          : ""}
                      </td>
                      <td>{row.INVOICENUMBER ? row.INVOICENUMBER : ""}</td>
                      <td>{row["SERVICE"] ? row["SERVICE"] : ""}</td>
                      <td className="text-right">
                        {row["DR"]
                          ? row["DR"].toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td className="text-right">
                        {row["CR"]
                          ? row["CR"].toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      {/* <td>{row["CURRENCY"] ? row["CURRENCY"] : ""}</td> */}
                      <td className="text-right">
                        {row["CUMMUALTIVE"]
                          ? row["CUMMUALTIVE"].toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}{" "}
                      </td>
                      {/* <td className="text-right">
                        {row["DEPOSIT"]
                          ? row["DEPOSIT"].toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
            <div className="relative flex bottom-0 justify-end right-[14px] w-full  z-10 pb-2 ">
              <div className=" flex  justify-end gap-8 pr-4 items-right">
                <div className="flex items-center custom-item">
                  <h2>Total DR :</h2>
                  <h2 className="font-bold text-lg text-red-500">
                    {totalDR
                      ? totalDR.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </h2>
                </div>
                <div className="flex items-center custom-item">
                  <h2>Total CR :</h2>
                  <h2 className="font-bold text-lg text-red-500">
                    {totalCR
                      ? totalCR.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </h2>
                </div>{" "}
                <div className="flex items-center custom-item">
                  <h2>Total Cummualtive :</h2>
                  <h2 className="font-bold text-lg text-red-500">
                    {totalcummualtive
                      ? totalcummualtive.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientMaster;
